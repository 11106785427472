<template>
  <div>
    <form v-if="!activeLoader" id="login-form" accept-charset="UTF-8">
      <div class="row sso-buttons">
        <div class="col text-center">
          <div class="mb-2">
            <b-button block class="btn-linked-in" :href="oauthLogin('linkedin')">
              <span class="float-left mr-1"><font-awesome-icon :icon="['fab', 'linkedin-in']" fixed-width /></span>
              <span class="mr-3">Log in with LinkedIn</span>
            </b-button>
          </div>
          <div class="mb-2">
            <b-button block variant="outline-dark" :href="oauthLogin('google')">
              <span class="float-left mr-1"><font-awesome-icon :icon="['fab', 'google']" fixed-width /></span>
              <span class="mr-3">Log in with Google</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="row text-center my-4">
        <div class="col">
          <div class="divider-line" />
          <div class="divider-or">
            or
          </div>
          <div class="divider-line" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group has-feedback">
            <label for="username" :class="{ 'text-danger': errors.has('username') }">
              <small>Email address or username
                <span v-show="errors.has('username')" class="form-control-feedback"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
              </small>
            </label>
            <input
              id="username"
              key="username"
              v-model="form.username"
              v-validate="'required'"
              data-vv-validate-on="blur|change"
              :class="{ 'is-invalid': errors.has('username') }"
              class="form-control"
              type="text"
              name="username">
          </div>
          <div class="form-group">
            <label for="password" :class="{ 'text-danger': errors.has('password') }">
              <small>Password
                <span v-show="errors.has('password')" class="form-control-feedback"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
              </small>
            </label>
            <input
              id="password"
              key="password"
              v-model="form.password"
              v-validate="'required'"
              data-vv-validate-on="blur|change"
              :class="{ 'is-invalid': errors.has('password') }"
              class="form-control"
              type="password"
              name="password">
            <small>
              <small><a class="btn-link forgot" :href="SPENV.APP_URL + '/iframes/reset-login.asp'">Forgot your password?</a></small>
            </small>
          </div>
          <div class="checkbox form-group">
            <label>
              <small>
                <input
                  id="remember_me"
                  v-model="form.remember_me"
                  type="checkbox"
                  name="remember_me">
                Remember me on this device
              </small>
            </label>
          </div>
          <div class="form-group text-center">
            <button id="login-submit" class="btn btn-outline-primary" type="submit"
                    name="loginSubmit" :disabled="submitting" @click="submit">
              Log in
            </button>
          </div>
          <div class="text-center p-2">
            <router-link :to="{ path: 'login/saml' }">
              Log in using SAML SSO
            </router-link>
          </div>
          <div class="text-center p-2">
            <router-link :to="{ path: 'signup/plan' }">
              Open an Account
            </router-link>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import redirectMixin from '@/utils/mixins/Redirect';
import loginRedirectMixin from '@/utils/mixins/LoginRedirect';

export default {
  name: 'Login',
  mixins: [loginRedirectMixin, redirectMixin],
  data() {
    return {
      form: {
        username: '',
        password: '',
        rememberme: '',
        title: '',
        body: '',
        show_alert: false,
        alert_message: '',
      },
      affkey: this.$route.query.affkey || this.$cookies.get('affiliate'),
      gclid: this.$route.query.gclid || this.$cookies.get('gclid'),
      campaign_id: this.$route.query.campaign_id,
      return_url: this.$route.query.return_url,
      lms_vars: null,
      query_string: '',
      submitting: false,
      activeLoader: false,
    };
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (_.includes(['pltw', 'pltwokta'], to.query.auth)) {
        vm.activeLoader = vm.$loading.show();
        vm.redirectUrl(vm.SPENV.ACCOUNTS_API_URL + '/oauth/auth-request?provider='+ to.query.auth + '&action=login&' + vm.query_string);
      }
    });
  },
  created() {
    // check if logged in already
    if (!this.user) {
      this.$store.dispatch('user/loadUserInfo').then(
        (response) => {
          // if logged in, redirect to the app
          if (this.isLoggedIn) {
            if (this.$route.query && this.$route.query.return_url) {
              this.redirectUrl(this.$route.query.return_url);
            } else {
              this.redirectUrl(this.SPENV.APP_URL);
            }
          }
        })
        .catch(
          (error) => {
            // kick to error state
            console.log(error);
          });
    }
    this.query_string = qs.stringify(this.$route.query); // querystring passed to oauth page
  },
  methods: {
    submit: function(event) {
      event.preventDefault();
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // make api call if form is valid
            let postBody = {
              username: this.form.username,
              password: this.form.password,
              rempass: this.form.remember_me,
              lms_vars: this.lms_vars,
            };
            if (this.affkey || this.campaign_id || this.gclid) {
              postBody.lms_vars = {
                affkey: this.affkey,
                campaign_id: this.campaign_id,
                gclid: this.gclid,
              };
            }
            if (this.return_url) {
              postBody.return_url = this.return_url;
            }
            this.submitting = true;
            axios.defaults.withCredentials = true; // Force Credentials
            axios.post(this.SPENV.ACCOUNTS_API_URL + '/login', postBody, { withCredentials: true }).then(
              (response) => {
                this.redirectUrl(response);
              })
              .catch(error => {
                console.log(error);
                this.submitting = false;
                this.$parent.$emit('login-alert', error.response.data);

              });
          }
        });
    },
    oauthLogin(provider) {
      return [
        this.SPENV.ACCOUNTS_API_URL,
        '/oauth/auth-request?provider=',
        provider,
        '&action=login&',
        this.query_string,
      ].join('');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
